import React, { useState } from 'react';
import { TextField, Container, Grid, createTheme, ThemeProvider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Create a custom theme with white background
const theme = createTheme({
  palette: {
    background: {
      default: '#fff',
    },
  },
});

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success('Form submitted successfully! We will be in touch.');
    setFormData({
      name: '',
      email: '',
      subject: '', 
      message: '',
    });  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <form onSubmit={handleSubmit} name="contact" data-netlify="true" method="post">
          <input type="hidden" name="form-name" value="contact" />
          <Grid container spacing={2}>
            {["name", "email", "subject", "message"].map((field) => (
              <Grid item xs={12} key={field}>
                <TextField
                  fullWidth
                  required
                  type={field === "email" ? "email" : "text"}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  variant="filled"
                  InputProps={{
                    style: {
                      backgroundColor: '#fff',
                    },
                  }}
                  multiline={field === "message"}
                  rows={field === "message" ? 4 : 1}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <button
                type="submit"
                style={{
                  border: 'none',
                  cursor: 'pointer',
                  width: '100%',
                  marginTop: '10px',
                  padding: '15px',
                  backgroundImage: 'linear-gradient(270deg, #5500FF, #FF00BF)',
                  color: '#fff',
                  borderRadius: '7px',
                  fontSize: '16px'
                }}
              >
                <strong>Send Message</strong>
              </button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </ThemeProvider>
  );
};

export default ContactForm;
