import React from 'react';
import { Link } from 'react-scroll'; 
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import heroimageblue from '../../img/hero_blue.svg'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { particlesOptions } from "../particlesConfig";
import bcomLogo from '../../img/bcom_logo.png'
import verseLogo from '../../img/verse_logo.svg'
import oneSignalLogo from '../../img/onesignal_logo.png'
import aboutUsImage from '../../img/about-us.svg'
import whoWeAreImage from '../../img/who-we-are.svg';
import synthwaveVectors from '../../img/synthwave-vectors.svg';
import connectedShapesImg from '../../img/connected-shapes.svg';
import scrollLogo from '../../img/scroll_logo.png';
import ethGlobalLogo from '../../img/eg-logo.svg';
import customerioLogo from '../../img/cio-logo.svg';
import ContactForm from '../../components/ContactForm';
import contactImg from '../../img/contact-img.svg'
import influencerImg from '../../img/influencerImg.svg'
import marketingCampaign from '../../img/marketingCampaign.svg'
import bigData from '../../img/bigData.svg'
import influencerMatch from '../../img/influencerMatch.svg'
import contractImg from '../../img/contractImg.svg'
import scalableImg from '../../img/scalableImg.svg'




function Home() {

  const services = [
      { 
          name: "Influencer Matching", 
          description: "We specialize in matching your brand with influencers who embody your values, enhancing your presence in the web3 space.", 
          image: influencerMatch
      },
      { 
          name: "Marketing Strategy", 
          description: "Our team creates authentic, user-generated content and narratives that boost engagement and increase visibility within the web3 community.", 
          image: influencerImg
      },
      { 
          name: "Reporting & Analytics", 
          description: "We track each campaign with precise KPIs and unique influencer codes, analyzing performance to continually refine your strategy.", 
          image: bigData 
      },
      { 
          name: "Content Management", 
          description: "Our strategies are crafted to maximize engagement by aligning content with your brand’s unique voice and promoting growth.", 
          image: marketingCampaign 
      },
      { 
          name: "Contracting", 
          description: "We simplify influencer contracting, ensuring transparent and legally sound collaborations tailored to your project's needs.", 
          image: contractImg 
      },
      { 
          name: "Scalable Approach", 
          description: "We enable your brand to scale influencer marketing, creating numerous impactful assets monthly to drive growth.", 
          image: scalableImg 
      }
    ];

  const particlesInit = (engine) => {
    loadFull(engine);
  };

  return (
    <div className="container">
      <div className="particles-container">
        <Particles init={particlesInit} options={particlesOptions} />
      </div>
      <div className="heroSection" id="heroSection">
          <div className="heroLeft">
            <div className="heroHeader">
                <p>UNLEASH <span className='heroTextHighlight'>GROWTH</span>.</p>
            </div>
            <div className="heroSubheader">
            <p>Boost your project with our <span className="heroSubTextHighlight">world-class web3 influencers</span>, reaching an audience of <span className="heroSubTextHighlight"> over 1.3 million</span>.</p>
            </div>
            <div className="heroButtonGroup">
            <Link to="contactSection" spy={true} smooth={true} offset={-150} duration={500} style={{ cursor: 'pointer' }}>
                <svg  className='mainHeroButton' xmlns="http://www.w3.org/2000/svg" width="223.5" height="57.75" viewBox="0 0 298 77" fill="none">
                    <path d="M53.4345 0H298L249.361 76.7264H0L53.4345 0Z" fill="url(#paint0_linear_11_3453)"/>
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#FFFFFF" fontSize="24px" fontWeight="bold">Contact us</text>
                    <defs>
                        <linearGradient id="paint0_linear_11_3453" x1="277.448" y1="38.3632" x2="19.1816" y2="38.3632" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#FF00BF"/>
                          <stop offset="1" stopColor="#5500FF"/>
                        </linearGradient>
                    </defs>
                </svg>
            </Link>
            </div>
          </div>
          <div className="heroRight">
          <img src={heroimageblue} alt="Hero Icon" />
          </div>
      </div>
      <div className="custom-shape-divider-bottom-1690554238">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M649.97 0L599.91 54.12 550.03 0 0 0 0 120 1200 120 1200 0 649.97 0z" className="shape-fill"></path>
          </svg>
      </div>
      <div className="aboutUs" id="aboutSection">
        <div className="aboutUsDiv">
        <Card sx={{ padding: '40px', backgroundColor: 'transparent', height: '100%', color: '#fff', boxShadow: 'none' }} className="aboutUsCard">
            <h2 className='aboutUsHeader'>Marketing is <span className='headerEmphasis'>Evolving</span>.</h2>
            <CardContent>
                <Typography className="aboutUsSubheader" sx={{ color: '#fff' }} variant="p" color="text.secondary">
                    <br /><br /> 
                    We realized that traditional marketing is rapidly becoming outdated. Our mission is to revolutionize product distribution for companies by tapping into the expansive network of content creators across various platforms.

                    <br /><br />
                    We’ve formed partnerships with world-class web3 influencers to offer you premium content distribution services at highly competitive rates.                </Typography>
            </CardContent>
        </Card>

          <div className="aboutImgDiv">
            <img src={aboutUsImage} className="aboutImg1" alt="About Us Icon" />
          </div>
        </div>
      </div>
      <div className="custom-shape-divider-top-1690553598">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M1200 0L0 0 598.97 114.72 1200 0z" className="shape-fill"></path>
          </svg>
      </div>
      
      <div className="ourServices" id="servicesSection">
        <h2 className='servicesHeader'>Our Services</h2>
        <div className="servicesCards">
          {services.map((service, index) => (
            <Card key={index} sx={{ backgroundColor: 'rgba(64, 0, 97, 0.5)', height: '100%' }}>
              <CardMedia
                component="img"
                height="auto"
                image={service.image}
                alt={service.name}
                style={{ height: '400px', objectFit: 'contain' }} 
              />
              <CardContent>
                <Typography sx={{ color: '#FFD300', fontWeight: 'bold'  }} gutterBottom variant="h5" component="div">
                  {service.name}
                </Typography>
                <Typography sx={{ color: '#fff', lineHeight: '25px' }} variant="h7" color="text.secondary">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      <div className="shapes-container">
        <img src={connectedShapesImg} alt="Connected Shapes" />
      </div>
      <div className="custom-shape-divider-bottom-1690549394">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
      </div>
      <div className="whoWeAre" id="whoWeAreSection">
      <div className="whoWeAreDiv">
    <div className="whoWeAreImgDiv">
        <img src={whoWeAreImage} alt="About Us Icon" />
    </div>
    <Card sx={{ borderRadius: '0px', padding: '40px', backgroundColor: 'rgba(64, 0, 97, 1)', height: '100%', color: '#fff', boxShadow: 'none' }} className="whoWeAreCard">
        <h2 className='whoWeAreHeader'>Who We Are</h2>
        <CardContent>
            <Typography className="whoWeAreSubheader" sx={{ color: '#fff' }} variant="p" color="text.secondary">
            Unleash Growth is led by a team of experienced marketers at the forefront of technological innovation. With a background in some of the biggest web3 entities, we have firsthand knowledge of what truly drives success in this dynamic field.            
            <br /><br />
            By collaborating with top-tier influencers and employing data-driven strategies, we ensure optimal performance and real-world application of your visionary projects. Partner with us to elevate your project and achieve unprecedented growth!
            </Typography>
        </CardContent>
    </Card>
</div>

      </div>
      <div className="custom-shape-divider-top-1690549627">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
      </div>
      <div className="ourPartners" id="partnersSection">
        <p className='partnersHeader'>Examples of Companies We Work With</p>
        <div className="partnersDiv">
            <img className="imgSize" src={bcomLogo} alt="Bcom Logo" />
            <img className="imgSize verseLogo" src={verseLogo} alt="Verse Logo" />
            <img className="imgSize" src={oneSignalLogo} alt="OneSignal Logo" />
            <img className="imgSize" src={ethGlobalLogo} alt="EthGlobal Logo" />
            <img className="imgSize" src={customerioLogo} alt="CustomerIO Logo" />
            <img className="imgSize scrollLogo" src={scrollLogo} alt="Scroll Network Logo" />
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1690617425">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
      </div>
      <div className="contactForm" id="contactSection">
        <p className='partnersHeader'>Drop us a message</p>
        <div className="contactFormDiv">
          <img src={contactImg} className="contactImg" alt="Lightbulb moment" />
          <ContactForm />
        </div>            
      </div>
      <div className="custom-shape-divider-top-1690617387">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
      </div>
      <div className="heroSocialButtons" id="socialsSection">
          <a href="https://t.me/UnleashGrowth" target="_blank"  rel="noopener noreferrer" className="iconDiv" style={{ textDecoration: 'none', margin: '35px', width: '220px', background: '#EC00C6', color: '#fff', fontSize: '30px', border: 'none' }}>
            <TelegramIcon fontSize="inherit" /> 
          <p style={{ paddingLeft: '10px' }}> <strong>Telegram</strong> </p>
          </a>
      </div>
      <div className="synthwaveContainer">
        <img src={synthwaveVectors} alt="Synthwave Vectors" />
      </div>
    </div>
      
  );
}

export default Home;
