import { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-scroll'; 
import Logo from '../unleash-growth-logos/Unleash Growth logo design  final 4.svg'

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position
      const scrollY = window.scrollY;

      // Determine whether to show or hide the navbar
      setShowNavbar(scrollY <= 0);
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Toggle mobile menu
  const handleMobileMenuToggle = (event) => {
    setMobileMenuOpen(!mobileMenuOpen);
    setMobileAnchorEl(event.currentTarget);
  };

  // Close mobile menu
  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
    setMobileAnchorEl(null);
  };

  return (
    <AppBar
      className="appBar"
      sx={{
        background: showNavbar ? 'transparent' : '#2E0046',
        boxShadow: showNavbar ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.3)',
      }}
      position="fixed"
    >
      <Toolbar className="navbar">
        {/* Hamburger Icon */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMobileMenuToggle}
          sx={{ display: { md: 'none' } }} // Hide on medium and larger screens
        >
          <MenuIcon />
        </IconButton>

        {/* Logo */}
        <Link className="custom-link" to="heroSection" spy={true} smooth={true} offset={-100} duration={500}>
          <img src={Logo} alt="Unleash Growth logo" style={{ height: '62px', width: 'auto', cursor: 'pointer' }} />
        </Link>

        {/* Desktop Menu */}
        <div
          className="navbarLinks"
          sx={{
            display: { xs: 'none', md: 'flex' }, // Show on small screens, hide on medium and larger screens
          }}
        >
          <Link to="aboutSection" spy={true} smooth={true} offset={-50} duration={500} >
            <Typography
              variant="h6"
              sx={{
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '32px',
                letterSpacing: '0.27px',
                '&:hover': { color: '#FFD300', cursor: 'pointer', textShadow: '0 0 5px #FFD300' },
                display: { md: 'inline', s: 'none', xs: 'none' }, // Show on medium and larger screens
              }}
            >
              About
            </Typography>
          </Link>
          <Link to="servicesSection" spy={true} smooth={true} offset={-50} duration={500}>
            <Typography
              variant="h6"
              sx={{
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '32px',
                letterSpacing: '0.27px',
                '&:hover': { color: '#FFD300', cursor: 'pointer', textShadow: '0 0 5px #FFD300' },
                display: { md: 'inline', s: 'none', xs: 'none' }, // Show on medium and larger screens
              }}
            >
              Services
            </Typography>
          </Link>
          <Link to="whoWeAreSection" spy={true} smooth={true} offset={-50} duration={500}>
            <Typography
              variant="h6"
              sx={{
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '32px',
                letterSpacing: '0.27px',
                '&:hover': { color: '#FFD300', cursor: 'pointer', textShadow: '0 0 5px #FFD300' },
                display: { md: 'inline', s: 'none', xs: 'none' }, // Show on medium and larger screens
              }}
            >
              Who We Are
            </Typography>

          </Link>
          <Link to="contactSection" spy={true} smooth={true} offset={-50} duration={500}>
            <Typography
              variant="h6"
              sx={{
                color: '#fff',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '32px',
                letterSpacing: '0.27px',
                '&:hover': { color: '#FFD300', cursor: 'pointer', textShadow: '0 0 5px #FFD300' },
                display: { md: 'inline', s: 'none', xs: 'none' }, // Show on medium and larger screens

              }}
            >
              Contact
            </Typography>
            
          </Link>
        </div>

        {/* Email and Mobile Menu */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* Email */}
          <EmailIcon
            sx={{
              fontSize: '18px',
              color: '#fff',
              marginRight: '4px',
              // display: { md: 'inline', s: 'none', xs: 'none' }, // Show on medium and larger screens
            }}
          />
          <Link to='socialsSection' spy={true} smooth={true} offset={-450} duration={500}>
            <Typography
              variant="h6"
              sx={{
                color: '#fff',
                fontFamily: 'Poppins',
                fontSize: '18px',
                fontWeight: 'normal',
                lineHeight: '32px',
                letterSpacing: '0.27px',
                '&:hover': { color: '#FFD300', cursor: 'pointer', textShadow: '0 0 5px #FFD300' },
                // display: { md: 'inline', s: 'none', xs: 'none' }, // Show on medium and larger screens
              }}
            >
              <strong>hello@unleashgrowth.io</strong>
            </Typography>
          </Link>

          {/* Mobile Menu */}
          <Menu
            anchorEl={mobileAnchorEl}
            open={mobileMenuOpen}
            onClose={handleMobileMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                backgroundColor: '#2E0046',
              },
            }}
            sx={{
              display: { md: 'none' }, // Hide on medium and larger screens
            }}
          >
            <MenuItem onClick={handleMobileMenuClose}>
              {/* Your mobile menu items go here */}
              <Link to="aboutSection" spy={true} smooth={true} offset={150} duration={500} >
                <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center' }}>
                  About
                </Typography>
              </Link>

            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
              {/* Your mobile menu items go here */}
              <Link to="servicesSection" spy={true} smooth={true} offset={-50} duration={500} >
                <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center' }}>
                  Services
                </Typography>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
              {/* Your mobile menu items go here */}
              <Link to="whoWeAreSection" spy={true} smooth={true} offset={-150} duration={500} >
                <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center' }}>
                  Who We Are
                </Typography>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
              {/* Your mobile menu items go here */}
              <Link to="contactSection" spy={true} smooth={true} offset={-150} duration={500} >
                <Typography variant="h6" sx={{ color: '#fff', textAlign: 'center' }}>
                  Contact
                </Typography>
              </Link>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
