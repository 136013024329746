import React from 'react';
import { Link } from 'react-scroll';


function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="site-footer">
          <div className="footerContainer">
            <div className="row">
              <div className="footerAboutDiv col-sm-12 col-md-6">
                <h6 className="footerHeader">About</h6>
                <p className="text-justify">We connect your brand with the right voices in the web3 space to amplify your message and expand your reach. Our mission is to craft impactful partnerships that resonate with your audience and drive engagement. We’re dedicated to enhancing your brand presence and achieving measurable results through strategic influencer collaborations. Partner with us to harness the transformative power of influencers and elevate your project to new heights.</p>
                <br />
                <p className='directOutreach'>Reach out directly: <strong>hello@unleashgrowth.io</strong></p>
              </div>

              <div className="col-xs-6 col-md-3">
                <h6 className="footerHeader">Services</h6>
                <ul className="footer-links">
                <li><Link to="servicesSection" smooth={true} duration={500} style={{ cursor: 'pointer' }}>Influencer Matching</Link></li>
                <li><Link to="servicesSection" smooth={true} duration={500} style={{ cursor: 'pointer' }}>Marketing Strategy</Link></li>
                <li><Link to="servicesSection" smooth={true} duration={500} style={{ cursor: 'pointer' }}>Reporting & Analytics</Link></li>
                <li><Link to="servicesSection" smooth={true} duration={500} style={{ cursor: 'pointer' }}>Content Management</Link></li>
                <li><Link to="servicesSection" smooth={true} duration={500} style={{ cursor: 'pointer' }}>Contracting</Link></li>
                <li><Link to="servicesSection" smooth={true} duration={500} style={{ cursor: 'pointer' }}>Scalable Approach</Link></li>
              </ul>
              </div>

              <div className="col-xs-6 col-md-3">
                <h6 className="footerHeader">Quick Links</h6>
                <ul className="footer-links">
                  <li><Link to="contactSection" style={{ cursor: 'pointer' }}>We're hiring!</Link></li>
                  <li><Link to="contactSection" style={{ cursor: 'pointer' }}>Contact Us</Link></li>
                  {/* <li><a href="https://tiktok.com/@unleashgrowth" target="_blank"  rel="noopener noreferrer" style={{ cursor: 'pointer' }}>TikTok</a></li> */}
                  <li><a href="https://t.me/UnleashGrowth" target="_blank"  rel="noopener noreferrer" style={{ cursor: 'pointer' }}>Telegram</a></li>
                </ul>
              </div>
            </div>
            <hr className='footerDivider' style={{ borderColor: '#EB00C6' }} />
          </div>
          <div className="footerContainer">
            <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <p className="copyright-text">Copyright &copy; {year} All Rights Reserved by Unleash Growth.</p>
              </div>
              {/* <div className="col-md-4 col-sm-6 col-xs-12">
                <ul className="social-icons">
                  <li><a className="facebook" href="#"><i className="fa fa-facebook"></i></a></li>
                  <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
                  <li><a className="dribbble" href="#"><i className="fa fa-dribbble"></i></a></li>
                  <li><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a></li>   
                </ul>
              </div> */}
            </div>
          </div>
    </footer>
  );
}

export default Footer;
